<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import {
  useAsyncData,
  useFlightService,
  useI18n,
  useConfigStore,
  useNavigation,
  useTracker,
  normalizeSlug,
} from '#imports';

const { locale } = useI18n();
const config = useConfigStore();
const device = useDevice();
const { navigationPath } = useNavigation();
const { jitsuEvent } = useTracker();

type Flight = {
  code: string;
  name: string;
  city: string;
  cityEN?: string;
  nameEN?: string;
};

type PopularRoute = {
  arrAirport: string;
  arrCity: string;
  arrCityEN: string;
  depAirport: string;
  depCity: string;
  depCityEN: string;
};

const { data: flights, execute: fetchFlights } = await useAsyncData(
  () => useFlightService().getPopularFlights({ destination: '', lang: locale.value, country: config.country! }),
  {
    server: device.isCrawler,
    immediate: device.isCrawler,
    transform: (data) => data.result.routes.slice(0, 15),
  },
);
const { data: routes, execute: fetchRoutes } = await useAsyncData(
  () => useFlightService().getPopularRoutes({ lang: locale.value, country: config.country! }),
  {
    server: device.isCrawler,
    immediate: device.isCrawler,
    transform: (data) => data.result,
  },
);

const container = ref(null);
const isContainerVisible = useElementVisibility(container);

const getFlightPath = (flight: { code: string; cityEN: string }) =>
  navigationPath('/flight/to/' + normalizeSlug(flight.cityEN + '-' + flight.code, true));

const getRoutePath = (route: { depAirport: string; depCityEN: string; arrAirport: string; arrCityEN: string }) =>
  navigationPath(
    '/flight-tickets/' +
      normalizeSlug(`${route.depCityEN}-${route.depAirport}-${route.arrCityEN}-${route.arrAirport}`, true),
  );

const onClickPopularFlight = (flight: Flight) => {
  jitsuEvent('user-click-button', {
    event_name: 'popularflight-click',
    product: 'flight',
    object_name: 'popular-footer',
    object_parameter: JSON.stringify({
      flights: flights.value!.join(','),
    }),
    city_arrival: flight.code,
  });
};

const onClickPopularRoute = (popularRoute: PopularRoute) => {
  jitsuEvent('user-click-button', {
    event_name: 'popularroutes-click',
    product: 'flight',
    object_name: 'popular-footer',
    object_parameter: JSON.stringify({
      routes: routes.value!.join(','),
    }),
    city_arrival: `${popularRoute.arrAirport}`,
    popular_routes: `${popularRoute.depAirport}${popularRoute.arrAirport}`,
  });
};

watchOnce(isContainerVisible, () => {
  fetchFlights();
  fetchRoutes();
});
</script>

<template>
  <div
    ref="container"
    class="flex flex-col text-center px-30"
  >
    <h2 class="text-base font-bold mb-20">
      {{ $t('global.populartitle') }}
    </h2>

    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="flex items-center justify-center text-small py-15"
        data-testid="top-popularFlight-button"
        :class="{ 'border-b-[0.5px] border-gray-lightest': !open }"
      >
        <h3>{{ $t('global.toppopularflight') }}</h3>

        <ApzIcon
          icon="angle-down"
          class="ml-10"
          :class="{ 'rotate-180': open }"
        />
      </DisclosureButton>

      <DisclosurePanel
        class="flex flex-col gap-y-10 pt-5 pb-15"
        :class="{ 'border-b-[0.5px] border-gray-lightest': open }"
        :unmount="false"
      >
        <NuxtLink
          v-for="flight in flights"
          :key="flight.code"
          :to="getFlightPath(flight)"
          external
          no-rel
          class="block text-small"
          data-testid="popular-flight-text"
          @click="onClickPopularFlight(flight)"
        >
          {{ $t('route.tocity', [flight.city]) }}
        </NuxtLink>
      </DisclosurePanel>
    </Disclosure>

    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="flex items-center justify-center text-small py-15"
        data-testid="top-popularRoutes-button"
        :class="{ 'border-b-[0.5px] border-gray-lightest': !open }"
      >
        <h3>{{ $t('global.toppopularroute') }}</h3>

        <ApzIcon
          icon="angle-down"
          class="ml-10"
          :class="{ 'rotate-180': open }"
        />
      </DisclosureButton>

      <DisclosurePanel
        class="flex flex-col gap-y-10 pt-5 pb-15"
        :unmount="false"
      >
        <NuxtLink
          v-for="route in routes"
          :key="`${route.depAirport}_${route.arrAirport}`"
          :to="getRoutePath(route)"
          external
          no-rel
          class="block text-small"
          data-testid="popular-routes-text"
          @click="onClickPopularRoute(route)"
        >
          {{ $t('route.textpoproute', [route.depCity, route.arrCity]) }}
        </NuxtLink>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>
